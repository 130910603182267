import React from 'react'
import { Col, Container, Row, Button, Modal, Form } from 'react-bootstrap'
import { Footer } from '../../components/Footer'
import { NavBar } from '../../components/NavBar'
import ScrollUp from '../../components/ScrollUp'
import { useState } from 'react'
import axios from 'axios'
import ApplyButton from '../../components/ui/ApplyButton'

const ApplicationsEngineer = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);  

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [uploaded_file, setUploadedFile] = useState(null);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleFileChange = (event) => {
        setUploadedFile(event.target.files[1]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('email', email);
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('subject', 'Job ---- Apply');
        formData.append('message', message);

        axios.post('http://localhost:4000/send-email', formData, uploaded_file, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
        })
        .then(response => {
            console.log('Data berhasil dikirim:', response.data);
        })
        .catch(error => {
            console.error('Terjadi kesalahan:', error.message);
        });
    };
    
  return (
    <div>
        <NavBar />
        <div className='contact-bg'></div>
        <Container className='career-info'>
            <Row>
                <Col>
                    <i class="fa-solid fa-building"></i>
                    <h3>Position</h3>
                    <h5>Project Document Control</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-location-dot"></i>
                    <h3>Location</h3>
                    <h5>Jakarta, Indonesia</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-award"></i>
                    <h3>Education</h3>
                    <h5>Bachelor Degree</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-file"></i>
                    <h3>Working Experience</h3>
                    <h5>5 Years</h5>
                </Col>
            </Row>
        </Container>
        <Container className='job-detail'>
            <h2>Job Description</h2>
            <p>Technical Support is preferably a Bachelor of Science degree or engineering and must be experienced in document control and document handling of major oil and gas Project. The Technical Support must have excellent detailed checking skills and interpersonal skills, excellent written and verbal communication skills and superior planning skills.</p>

            <h2>Qualification</h2>
            <ul>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Fluent English Speaking & Writing </li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Active User of Microsoft programs</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Able to manage Documents for large Scale Projects ( over 150 B Rupiah )</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Stake holder Management active</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Willing travelling outside Jakarta ( Site Based )</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Age Must be 30 - 35 years</li>
            </ul>
        </Container>
        <div className='apply-button'><button><ApplyButton label="Apply This Job" mailto="mailto:recruitment@soliner.co.id"/></button></div>
        <Footer />
        <ScrollUp />
    </div>
  )
}

export default ApplicationsEngineer;