import React from 'react'
import { Col, Container, Row, Button, Modal, Form } from 'react-bootstrap'
import { Footer } from '../../components/Footer'
import { NavBar } from '../../components/NavBar'
import ScrollUp from '../../components/ScrollUp'
import { useState } from 'react'
import ApplyButton from '../../components/ui/ApplyButton'

const HSECoordinator = () => {
                
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);  

  return (
    <div>
        <NavBar />
        <div className='contact-bg'></div>
        <Container className='career-info'>
            <Row>
                <Col>
                    <i class="fa-solid fa-building"></i>
                    <h3>Position</h3>
                    <h5>Project HSSE Lead / Manager</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-location-dot"></i>
                    <h3>Location</h3>
                    <h5>Jakarta</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-award"></i>
                    <h3>Education</h3>
                    <h5>Bachelor Degree</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-file"></i>
                    <h3>Working Experience</h3>
                    <h5>10 Years</h5>
                </Col>
            </Row>
        </Container>
        <Container className='job-detail'>
            <h2>Job Description</h2>
            <p>The role of the HSSE Specialist is to develop the HSSE Management Plan, HSSE Philosophy and Targets, Safety Standards, Safety Case and to ensure that the PROJECT meets or exceeds the health and safety aspects of COMPANY HSSE expectations. He/she must challenge the design during all phases of the PROJECT, liaise between the discipline teams, coach the team members on HSSE issues, monitor and audit HSSE performance, and implement continuous improvement/ apply lessons learned. The HSSE Specialist must have previous experience conducting and leading formal HSSE assessments, leading HSSE workshops, development of HSSE Plan, and setting performance standards. The HSSE Specialist shall have extensive field experiences in particular during EPCI stage and must have a working knowledge of applicable industry practices and regulations. The HSSE Specialist should have sufficient environmental experience to cover any environmental issues, which may arise.
            </p>

            <h2>Responsibilities</h2>
            <ul>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Fluent English Speaking & Writing</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Experience in Construction Oil & Gas Projects</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Active User of Microsoft programs</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Able to develop Corporate Procurement Policy, Procedure & Work Instruction according to ISO & Company needs</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Able to manage large Scale Projects ( over 150 B Rupiah )</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Able to manage Project control & cost control team"</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Age Must be 30 - 45 years</li>
            </ul>
        </Container>
        <div className='apply-button'><button><ApplyButton label="Apply This Job" mailto="mailto:recruitment@soliner.co.id"/></button></div>
        <Footer />
        <ScrollUp />
    </div>
  )
}

export default HSECoordinator;