
import { ClientLayout } from './ClientLayout';
import { Container } from 'react-bootstrap';
import { PartnerLayout } from './PartnerLayout';

function ClientAndPartner() {
  return (
    <Container>
      <h1 className='text-client'>Our Clients</h1>
      <ClientLayout/>
      <h1 className='text-client'>Our Products</h1>
      <PartnerLayout/>
    </Container>
  );
}

export default ClientAndPartner;