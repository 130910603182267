import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Footer } from '../../components/Footer'
import { NavBar } from '../../components/NavBar'

const FinanceAndAdministration = () => {
  return (
    <div>
      <NavBar />
      <div className='contact-bg'></div>
        <Container className='positions'>
            <div className='job'>
              <h3>Corporate Secretary</h3>
              <Row>
                <Col className='job-description'>
                  <h5>Full Time</h5>
                </Col>
                <Col className='job-description'>
                  <h5>Contract</h5>
                </Col>
              </Row>
            </div>

            <div className='job2'>
              <div className='job-location'>
                <h3>Jakarta</h3>
                <h5>Indonesia</h5>
              </div>
              <a href='job-11'>
                <button>View And Apply</button>
              </a>
            </div>
          </Container>

          <Container className='positions'>
              <div className='job'>
                <h3>Other Position</h3>
                <Row>
                  <Col className='job-description'>
                    <h5>Full Time</h5>
                  </Col>
                  <Col className='job-description'>
                    <h5>Contract</h5>
                  </Col>
                </Row>
              </div>

              <div className='job2'>
                <div className='job-location'>
                  <h3>Jakarta</h3>
                  <h5>Indonesia</h5>
                </div>
                <a href='job/other'>
                  <button>View And Apply</button>
                </a>
              </div>
            </Container>
      <Footer />
    </div>
  )
}

export default FinanceAndAdministration