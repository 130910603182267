import React from 'react'
import Marquee from "react-fast-marquee";
import logo1 from '../assets/img/brand-logo/1.jpg';
import logo2 from '../assets/img/brand-logo/2.jpg';
import logo3 from '../assets/img/brand-logo/3.jpg';
import logo4 from '../assets/img/brand-logo/4.jpg';
import logo5 from '../assets/img/brand-logo/5.jpg';
import logo6 from '../assets/img/brand-logo/6.jpg';
import logo7 from '../assets/img/brand-logo/7.jpg';
import logo8 from '../assets/img/brand-logo/8.jpg';
import logo9 from '../assets/img/brand-logo/9.jpg';
import logo10 from '../assets/img/brand-logo/10.jpg'
import logo11 from '../assets/img/brand-logo/11.jpg';
import logo12 from '../assets/img/brand-logo/12.jpg';
import logo13 from '../assets/img/brand-logo/13.jpg';
import logo14 from '../assets/img/brand-logo/14.jpg';
import logo15 from '../assets/img/brand-logo/15.jpg';
import logo16 from '../assets/img/brand-logo/16.jpg';
import logo17 from '../assets/img/brand-logo/17.jpg';
import logo18 from '../assets/img/brand-logo/18.jpg';
import logo19 from '../assets/img/brand-logo/19.jpg';
import logo20 from '../assets/img/brand-logo/20.jpg';
import logo21 from '../assets/img/brand-logo/21.jpg';
import logo22 from '../assets/img/brand-logo/22.jpg';
import logo23 from '../assets/img/brand-logo/23.jpg';
import logo24 from '../assets/img/brand-logo/24.jpg';
import logo25 from '../assets/img/brand-logo/25.jpg';
import { Container } from 'react-bootstrap';

export const ClientLayout = () => {
    return (
        <Marquee speed={50}>
            <Container className='partner-container'>
                <img src={logo1} alt='logo' className='partner-logo' />
            </Container>

            <Container className='partner-container'>
                <img src={logo2} alt='logo' className='partner-logo' />
            </Container>

            <Container className='partner-container'>
                <img src={logo3} alt='logo' className='partner-logo' />
            </Container>

            <Container className='partner-container'>
                <img src={logo4} alt='logo' className='partner-logo' />
            </Container>

            <Container className='partner-container'>
                <img src={logo5} alt='logo' className='partner-logo' />
            </Container>

            <Container className='partner-container'>
                <img src={logo6} alt='logo' className='partner-logo' />
            </Container>

            <Container className='partner-container'>
                <img src={logo7} alt='logo' className='partner-logo' />
            </Container>

            <Container className='partner-container'>
                <img src={logo8} alt='logo' className='partner-logo' />
            </Container>

            
            <Container className='partner-container'>
                <img src={logo9} alt='logo' className='partner-logo' />
            </Container>

            <Container className='partner-container'>
                <img src={logo10} alt='logo' className='partner-logo' />
            </Container>

            <Container className='partner-container'>
                <img src={logo11} alt='logo' className='partner-logo' />
            </Container>

            <Container className='partner-container'>
                <img src={logo12} alt='logo' className='partner-logo' />
            </Container>

            <Container className='partner-container'>
                <img src={logo13} alt='logo' className='partner-logo' />
            </Container>

            <Container className='partner-container'>
                <img src={logo14} alt='logo' className='partner-logo' />
            </Container>

            <Container className='partner-container'>
                <img src={logo15} alt='logo' className='partner-logo' />
            </Container>

            <Container className='partner-container'>
                <img src={logo16} alt='logo' className='partner-logo' />
            </Container>

            <Container className='partner-container'>
                <img src={logo17} alt='logo' className='partner-logo' />
            </Container>

            <Container className='partner-container'>
                <img src={logo18} alt='logo' className='partner-logo' />
            </Container>

            <Container className='partner-container'>
                <img src={logo19} alt='logo' className='partner-logo' />
            </Container>
            <Container className='partner-container'>
                <img src={logo20} alt='logo' className='partner-logo' />
            </Container>
            <Container className='partner-container'>
                <img src={logo21} alt='logo' className='partner-logo' />
            </Container>
            <Container className='partner-container'>
                <img src={logo22} alt='logo' className='partner-logo' />
            </Container>
            <Container className='partner-container'>
                <img src={logo23} alt='logo' className='partner-logo' />
            </Container>
            <Container className='partner-container'>
                <img src={logo24} alt='logo' className='partner-logo' />
            </Container>
            <Container className='partner-container'>
                <img src={logo25} alt='logo' className='partner-logo' />
            </Container>
        </Marquee>
    )
}
 