import React from 'react'
import { Container } from 'react-bootstrap'
import { Footer } from '../../components/Footer'
import { NavBar } from '../../components/NavBar'

const ConstructionManagement = () => {
  return (
    <div>
      <NavBar />
      <div className='contact-bg'></div>
      <Container className='positions' style={{height: '20vh'}}>

      </Container>
      <Footer />
    </div>
  )
}

export default ConstructionManagement