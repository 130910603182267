import React from 'react'
import { NavBar } from '../components/NavBar'
import { Fade } from 'react-reveal'
import { Col, Container, Row } from 'react-bootstrap'
import thumbnail1 from '../assets/img/documentation/1.JPG'
import thumbnail2 from '../assets/img/documentation/2.jpg'
import thumbnail3 from '../assets/img/documentation/3.JPG'
import thumbnail4 from '../assets/img/documentation/4.jpeg'
import { Footer } from '../components/Footer'
import ScrollUp from '../components/ScrollUp'


const Career = () => {
  return (
    <div>
        <NavBar />
        <div className='bg-career'>
          <Fade>
            <h1>Careers
              <div className='tagline'></div>
            </h1>
            <p>We truly believe that to do great work is to love what you do. To bring out the best in our team, <br /> Soliner strives to build an organization that people love with Responsible Honesty, Extra Value, Authentic, Meaningful and Simple.</p>
          </Fade>
        </div>
        <Container className='career-content'>
          <h1>Join Us</h1>
          <Row xs={1} md={2} xl={3}>
              <a href='sector-1'>
                <div className='career-wrapper'>
                  <h3>Project Management</h3>
                  <h5>0 Position</h5>
                </div>
              </a>

              <a href='sector-4'>
                <div className='career-wrapper'>
                  <h3>Construction Management</h3>
                  <h5>0 Position</h5>
                </div>
              </a>

              <a href='sector-2'>
                <div className='career-wrapper'>
                  <h3>Discipline Engineer</h3>
                  <h5>5 Position</h5>
                </div>
              </a>

              <a href='sector-5'>
                <div className='career-wrapper'>
                  <h3>Marketing And Procurement</h3>
                  <h5>0 Position</h5>
                </div>
              </a>

              <a href='/sector-3'>
                <div className='career-wrapper'>
                  <h3>Finance And Administration</h3>
                  <h5>2 Position</h5>
                </div>
              </a>

              <a href='sector-6'>
                <div className='career-wrapper'>
                  <h3>Project Services</h3>
                  <h5>2 Position</h5>
                </div>
              </a>
          </Row>
          <h1>Come Work With Us</h1>
          <Row className='row2' md={1} lg={2}>
            <Col className='col1'>
              <h3>Our Culture</h3>
              <p>Coming together is the beginning...... staying together is progress.... working together is success.... come join us building Indonesia together</p>
            </Col>


            <div className='container-document'>
              <div className='documentation-wrapper'>
                <div className='documentation'>
                  <img src={thumbnail1} />
                  <div className='D-overlay'></div>
                </div>

                <div className='documentation'>
                  <img src={thumbnail2} />
                  <div className='D-overlay'></div>
                </div>
              </div>

              <div className='documentation-wrapper'>
                <div className='documentation'>
                  <img src={thumbnail3} />
                  <div className='D-overlay'></div>
                </div>

                <div className='documentation'>
                  <img src={thumbnail4} />
                  <div className='D-overlay'></div>
                </div>
              </div>
            </div>
          </Row>
          <h1>Life At SOLINER</h1>
          <Row className='row3' xs={2} xl={4}>
            <Col>
              <i class="fa-solid fa-flask"></i>
              <h3>Global Operations</h3>
            </Col>

            <Col>
              <i class="fa-solid fa-gas-pump"></i>
              <h3>Shell Bussines</h3>
            </Col>

            <Col>
              <i class="fa-solid fa-bottle-droplet"></i>
              <h3>Diversity & Inclusion</h3>
            </Col>

            <Col>
              <i class="fa-solid fa-sailboat"></i>
              <h3>Experienced Professionals</h3>
            </Col>
          </Row>
          <h1>Open Positions</h1>
          <Col className='col3'>
            <div className='positions'>
              <div className='job'>
                <h3>Project Manager</h3>
                <Row>
                  <Col className='job-description'>
                    <h5>Full Time</h5>
                  </Col>
                  <Col className='job-description'>
                    <h5>Contract</h5>
                  </Col>
                </Row>
              </div>

              <div className='job2'>
                <div className='job-location'>
                  <h3>Jakarta</h3>
                  <h5>Indonesia</h5>
                </div>
                <a href='/job-1'>
                  <button>View And Apply</button>
                </a>
              </div>
            </div>

            <div className='positions'>
              <div className='job'>
                <h3>Piping Engineer /<br /> Super Intendent</h3>
                <Row>
                  <Col className='job-description'>
                    <h5>Full Time</h5>
                  </Col>
                  <Col className='job-description'>
                    <h5>Contract</h5>
                  </Col>
                </Row>
              </div>

              <div className='job2'>
                <div className='job-location'>
                  <h3>Jakarta</h3>
                  <h5>Indonesia</h5>
                </div>
                <a href='job-2'>
                  <button>View And Apply</button>
                </a>
              </div>
            </div>

            <div className='positions'>
              <div className='job'>
                <h3>Project Planner</h3>
                <Row>
                  <Col className='job-description'>
                    <h5>Full Time</h5>
                  </Col>
                  <Col className='job-description'>
                    <h5>Contract</h5>
                  </Col>
                </Row>
              </div>

              <div className='job2'>
                <div className='job-location'>
                  <h3>Jakarta</h3>
                  <h5>Indonesia</h5>
                </div>
                <a href='job-3'>
                  <button>View And Apply</button>
                </a>
              </div>
            </div>

            <div className='positions'>
              <div className='job'>
                <h3>Project HSSE <br />Lead / Manager</h3>
                <Row>
                  <Col className='job-description'>
                    <h5>Full Time</h5>
                  </Col>
                  <Col className='job-description'>
                    <h5>Contract</h5>
                  </Col>
                </Row>
              </div>

              <div className='job2'>
                <div className='job-location'>
                  <h3>Jakarta</h3>
                  <h5>Indonesia</h5>
                </div>
                <a href='job-4'>
                  <button>View And Apply</button>
                </a>
              </div>
            </div>

            <div className='positions'>
              <div className='job'>
                <h3>Lifting Engineer /<br /> Super Intendent</h3>
                <Row>
                  <Col className='job-description'>
                    <h5>Full Time</h5>
                  </Col>
                  <Col className='job-description'>
                    <h5>Contract</h5>
                  </Col>
                </Row>
              </div>

              <div className='job2'>
                <div className='job-location'>
                  <h3>Jakarta</h3>
                  <h5>Indonesia</h5>
                </div>
                <a href='job-5'>
                  <button>View And Apply</button>
                </a>
              </div>
            </div>

            <div className='positions'>
              <div className='job'>
                <h3>Procurement <br />Manager</h3>
                <Row>
                  <Col className='job-description'>
                    <h5>Full Time</h5>
                  </Col>
                  <Col className='job-description'>
                    <h5>Contract</h5>
                  </Col>
                </Row>
              </div>

              <div className='job2'>
                <div className='job-location'>
                  <h3>Jakarta</h3>
                  <h5>Indonesia</h5>
                </div>
                <a href='job-6'>
                  <button>View And Apply</button>
                </a>
              </div>
            </div>

            <div className='positions'>
              <div className='job'>
                <h3>Mechanical <br />Engineer</h3>
                <Row>
                  <Col className='job-description'>
                    <h5>Full Time</h5>
                  </Col>
                  <Col className='job-description'>
                    <h5>Contract</h5>
                  </Col>
                </Row>
              </div>

              <div className='job2'>
                <div className='job-location'>
                  <h3>Jakarta</h3>
                  <h5>Indonesia</h5>
                </div>
                <a href='job-7'>
                  <button>View And Apply</button>
                </a>
              </div>
            </div>

            <div className='positions'>
              <div className='job'>
                <h3>Civil  Engineer /<br /> Super Intendent</h3>
                <Row>
                  <Col className='job-description'>
                    <h5>Full Time</h5>
                  </Col>
                  <Col className='job-description'>
                    <h5>Contract</h5>
                  </Col>
                </Row>
              </div>

              <div className='job2'>
                <div className='job-location'>
                  <h3>Jakarta</h3>
                  <h5>Indonesia</h5>
                </div>
                <a href='job-8'>
                  <button>View And Apply</button>
                </a>
              </div>
            </div>

            <div className='positions'>
              <div className='job'>
                <h3>QA / QC Manager</h3>
                <Row>
                  <Col className='job-description'>
                    <h5>Full Time</h5>
                  </Col>
                  <Col className='job-description'>
                    <h5>Contract</h5>
                  </Col>
                </Row>
              </div>

              <div className='job2'>
                <div className='job-location'>
                  <h3>Jakarta</h3>
                  <h5>Indonesia</h5>
                </div>
                <a href='job-9'>
                  <button>View And Apply</button>
                </a>
              </div>
            </div>

            <div className='positions'>
              <div className='job'>
                <h3>Project Document<br /> Control</h3>
                <Row>
                  <Col className='job-description'>
                    <h5>Full Time</h5>
                  </Col>
                  <Col className='job-description'>
                    <h5>Contract</h5>
                  </Col>
                </Row>
              </div>

              <div className='job2'>
                <div className='job-location'>
                  <h3>Jakarta</h3>
                  <h5>Indonesia</h5>
                </div>
                <a href='job-10'>
                  <button>View And Apply</button>
                </a>
              </div>
            </div>
            
            <div className='positions'>
              <div className='job'>
                <h3>Corporate Secretary</h3>
                <Row>
                  <Col className='job-description'>
                    <h5>Full Time</h5>
                  </Col>
                  <Col className='job-description'>
                    <h5>Contract</h5>
                  </Col>
                </Row>
              </div>

              <div className='job2'>
                <div className='job-location'>
                  <h3>Jakarta</h3>
                  <h5>Indonesia</h5>
                </div>
                <a href='job-11'>
                  <button>View And Apply</button>
                </a>
              </div>
            </div>

            <div className='positions'>
              <div className='job'>
                <h3>Other Position</h3>
                <Row>
                  <Col className='job-description'>
                    <h5>Full Time</h5>
                  </Col>
                  <Col className='job-description'>
                    <h5>Contract</h5>
                  </Col>
                </Row>
              </div>

              <div className='job2'>
                <div className='job-location'>
                  <h3>Jakarta</h3>
                  <h5>Indonesia</h5>
                </div>
                <a href='job/other'>
                  <button>View And Apply</button>
                </a>
              </div>
            </div>
          </Col>
        </Container>
        <Footer />
        <ScrollUp />
    </div>
  )
}

export default Career