import React, { Component } from 'react'
import Marquee from "react-fast-marquee";
import product1 from '../assets/img/product/1.jpg'
import product2 from '../assets/img/product/2.png'
import product3 from '../assets/img/product/3.jpg'
import product4 from '../assets/img/product/4.jpg'
import product5 from '../assets/img/product/5.jpg'
import product6 from '../assets/img/product/6.jpg'
import product7 from '../assets/img/product/7.jpg'
import product8 from '../assets/img/product/8.jpg'
import product9 from '../assets/img/product/9.jpg'
import product10 from '../assets/img/product/10.jpg'
import product11 from '../assets/img/product/11.jpg'
import product12 from '../assets/img/product/12.jpg'
import product13 from '../assets/img/product/13.jpg'
import product14 from '../assets/img/product/14.jpg'
import product15 from '../assets/img/product/15.jpg'
import product16 from '../assets/img/product/16.jpg'
import product17 from '../assets/img/product/17.jpg'
import product18 from '../assets/img/product/18.jpg'
import product19 from '../assets/img/product/19.jpg'
import product20 from '../assets/img/product/20.jpg'
import product21 from '../assets/img/product/21.jpg'
import product22 from '../assets/img/product/22.jpg'
import product23 from '../assets/img/product/23.jpg'
import product24 from '../assets/img/product/24.jpg'
import product25 from '../assets/img/product/25.jpg'
import product26 from '../assets/img/product/26.jpg'
import product27 from '../assets/img/product/27.jpg'
import product28 from '../assets/img/product/28.jpg'
import product29 from '../assets/img/product/29.jpg'
import product30 from '../assets/img/product/30.jpg'
import product31 from '../assets/img/product/31.jpg'
import product32 from '../assets/img/product/3.jpg'
import { Container } from 'react-bootstrap';

export const PartnerLayout = () => {
    return (
        <Marquee speed={100} direction='right'>
                <Container className='partner-container'>
                    <img src={product1} alt='logo' className='product-logo' />
                    <h5>Ball Valve</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product2} alt='logo' className='product-logo' />
                    <h5>Ball Valve</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product3} alt='logo' className='product-logo' />
                    <h5>Gate Valve</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product4} alt='logo' className='product-logo' />
                    <h5>Gate Valve</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product5} alt='logo' className='product-logo' />
                    <h5>Swing Check Valve</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product6} alt='logo' className='product-logo' />
                    <h5>Check Valve</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product7} alt='logo' className='product-logo' />
                    <h5>Globe Valve</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product8} alt='logo' className='product-logo' />
                    <h5>Globe Valve</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product9} alt='logo' className='product-logo' />
                    <h5>Y Strainer</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product10} alt='logo' className='product-logo' />
                    <h5>Y Strainer</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product11} alt='logo' className='product-logo' />
                    <h5>NPT Threaded Flange</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product12} alt='logo' className='product-logo' />
                    <h5>OR Spiral Wound Gasket</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product13} alt='logo' className='product-logo' />
                    <h5>Slip On Flange</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product14} alt='logo' className='product-logo' />
                    <h5>Ring Joint Gasket</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product15} alt='logo' className='product-logo' />
                    <h5>Blind Flange</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product16} alt='logo' className='product-logo' />
                    <h5>SW Flange</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product17} alt='logo' className='product-logo' />
                    <h5>WN Flange</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product18} alt='logo' className='product-logo' />
                    <h5>Bolts & Nuts</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product19} alt='logo' className='product-logo' />
                    <h5>Bolts & Nuts</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product20} alt='logo' className='product-logo' />
                    <h5>90 Deg Elbow</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product21} alt='logo' className='product-logo' />
                    <h5>45 Deg. Elbow</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product22} alt='logo' className='product-logo' />
                    <h5>Straight Tees</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product23} alt='logo' className='product-logo' />
                    <h5>Concentric Reducer</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product24} alt='logo' className='product-logo' />
                    <h5>Eccentric Reducer</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product25} alt='logo' className='product-logo' />
                    <h5>Cap</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product26} alt='logo' className='product-logo' />
                    <h5>Weldolet</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product27} alt='logo' className='product-logo' />
                    <h5>Sockolet</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product28} alt='logo' className='product-logo' />
                    <h5>Hex Plug</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product29} alt='logo' className='product-logo' />
                    <h5>Union</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product30} alt='logo' className='product-logo' />
                    <h5>Carbon Steel Pipe</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product31} alt='logo' className='product-logo' />
                    <h5>Stainless Steel Pipe</h5>
                </Container>
                <Container className='partner-container'>
                    <img src={product32} alt='logo' className='product-logo' />
                    <h5>HDPE Pipe</h5>
                </Container>
        </Marquee>
    )
}