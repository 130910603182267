import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { Footer } from '../components/Footer'
import { NavBar } from '../components/NavBar'
import pj1 from '../assets/img/documentation/1.JPG'
import pj2 from '../assets/img/documentation/2.jpg'
import pj3 from '../assets/img/documentation/3.JPG'
import pj4 from '../assets/img/documentation/4.jpeg'
import pj5 from '../assets/img/documentation/5.JPG'
import pj6 from '../assets/img/documentation/6.JPG'
import pj7 from '../assets/img/documentation/7.JPG'
import pj8 from '../assets/img/documentation/8.JPG'
import pj9 from '../assets/img/documentation/9.JPG'
import pj10 from '../assets/img/documentation/10.jpg'
import pj11 from '../assets/img/documentation/11.jpg'
import pj12 from '../assets/img/documentation/12.jpg'
import pj13 from '../assets/img/documentation/13.JPG'
import pj14 from '../assets/img/documentation/14.JPG'
import pj15 from '../assets/img/documentation/15.JPG'
import pj16 from '../assets/img/documentation/16.JPG'
import pj18 from '../assets/img/documentation/18.JPG'
import pj19 from '../assets/img/documentation/19.jpeg'
import pj20 from '../assets/img/documentation/20.jpeg'
import ScrollUp from '../components/ScrollUp'

const Project = () => {
  return (
    <div>
        <NavBar />
        <div className='contact-bg'></div>
        <h1 className='gallery'>Gallery</h1>
        <Container>
          <Row xs={2} lg={3} xxl={4} className="g-4">
            <Col>
              <Card>
                <Card.Img variant="top" src={pj1} />
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={pj2} />
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={pj3} />
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={pj4} />
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={pj5} />
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={pj6} />
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={pj7} />
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={pj8} />
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={pj9} />
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={pj10} />
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={pj11} />
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={pj12} />
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={pj13} />
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={pj14} />
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={pj15} />
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={pj16} />
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={pj18} />
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={pj19} />
              </Card>
            </Col>
          </Row>
        </Container>
        <Footer />
        <ScrollUp />
    </div>
  )
}

export default Project