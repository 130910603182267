import React from 'react'
import { Col, Container, Row, Button, Modal, Form } from 'react-bootstrap'
import { Footer } from '../../components/Footer'
import { NavBar } from '../../components/NavBar'
import ScrollUp from '../../components/ScrollUp'
import { useState } from 'react'
import ApplyButton from '../../components/ui/ApplyButton'


const ProjectPlanner = () => {
            
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);  

  return (
    <div>
        <NavBar />
        <div className='contact-bg'></div>
        <Container className='career-info'>
            <Row>
                <Col>
                    <i class="fa-solid fa-building"></i>
                    <h3>Position</h3>
                    <h5>Project Control Lead<br/>( Planner )</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-location-dot"></i>
                    <h3>Location</h3>
                    <h5>Jakarta, Indonesia</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-award"></i>
                    <h3>Education</h3>
                    <h5>Bachelor Degree</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-file"></i>
                    <h3>Working Experience</h3>
                    <h5>10 Years</h5>
                </Col>
            </Row>
        </Container>
        <Container className='job-detail'>
            <h2>Job Description</h2>
            <p>The Project Control Lead shall assist the Project Manager with Project organization, Project planning, setting objectives and priorities, monitoring progress, conducting audits and reviews, and ensuring that sufficient resources are available to perform the work scope and meet Client’s technical, cost and schedule objectives. He/She also prepare and monitor a critical path method (CPM) schedule for the PROJECT to identify the resources and time required to complete the PROJECT; perform sensitivity analysis on the CPM schedule to determine potential areas of slippage and corrective active to be taken; and keep management informed of the progress of the Work. He/she should also overview the cost estimate development and the cost control activities. 
            </p>

            <h2>Qualification</h2>
            <ul>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Fluent English Speaking & Writing</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Experience in Construction Oil & Gas Projects</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Active User of Microsoft Programs</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Microsoft Project</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Primavera Perverable</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Able to Manage Scale Project (Over 150 B rupiah)</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Willing travelling outside Jakarta</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Age Must be 30 - 45 years</li>
            </ul>
        </Container>
        <div className='apply-button'><button><ApplyButton label="Apply This Job" mailto="mailto:recruitment@soliner.co.id"/></button></div>
        <Footer />
        <ScrollUp />
    </div>
  )
}

export default ProjectPlanner;