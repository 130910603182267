import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Home from './pages/Home'
import Services from './pages/Services'
import About from './pages/About'
import Contact from './pages/Contact'
import Career from './pages/Career'
import ProjectManagement from './pages/sector/ProjectManagement';
import DisciplineEngineer from './pages/sector/DisciplineEngineer';
import FinanceAndAdministration from './pages/sector/FinanceAndAdministration';
import ConstructionManagement from './pages/sector/ConstructionManagement';
import MarketingAndProcurement from './pages/sector/MarketingAndProcurement';
import ProjectServices from './pages/sector/ProjectServices';
import ProjectManager from './pages/jobDesk/ProjectManager';
import ConstructionManager from './pages/jobDesk/ConstructionManager';
import Project from './pages/Project';
import ProjectPlanner from './pages/jobDesk/ProjectPlanner';
import HSECoordinator from './pages/jobDesk/HSECoordinator';
import ProcurementSubcontracting from './pages/jobDesk/ProcurementSubcontracting';
import ProcurementPurchasing from './pages/jobDesk/ProcurementPurchasing';
import MechanicalEngineer from './pages/jobDesk/MechanicalEngineer';
import LiftingEngineer from './pages/jobDesk/LiftingEngineer';
import LeadQualityControl from './pages/jobDesk/LeadQualityControl';
import ApplicationsEngineer from './pages/jobDesk/ApplicationsEngineer';
import CorporateSecretary from './pages/jobDesk/CorporateSecretary';
import OtherPosition from './pages/jobDesk/OtherPosition';

const App = () => {

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (

  <div className="app">
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : (
      <BrowserRouter>
          <Routes> 
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/contact-us" element={<Contact/>}/>
            <Route path="/career" element={<Career/>}/>
            <Route path="/project" element={<Project/>}/>
            <Route path="/sector-1" element={<ProjectManagement />}/>
            <Route path="/sector-2" element={<DisciplineEngineer />}/>
            <Route path="/sector-3" element={<FinanceAndAdministration />}/>
            <Route path="/sector-4" element={<ConstructionManagement />}/>
            <Route path="/sector-5" element={<MarketingAndProcurement />}/>
            <Route path="/sector-6" element={<ProjectServices />}/>
            <Route path="/job-1" element={<ProjectManager />}/>
            <Route path="/job-2" element={<ConstructionManager />}/>
            <Route path="/job-3" element={<ProjectPlanner />}/>
            <Route path="/job-4" element={<HSECoordinator />}/>
            <Route path="/job-5" element={<ProcurementSubcontracting />}/>
            <Route path="/job-6" element={<ProcurementPurchasing />}/>
            <Route path="/job-7" element={<MechanicalEngineer />}/>
            <Route path="/job-8" element={<LiftingEngineer />}/>
            <Route path="/job-9" element={<LeadQualityControl />}/>
            <Route path="/job-10" element={<ApplicationsEngineer />}/>
            <Route path="/job-11" element={<CorporateSecretary />}/>
            <Route path="/job/other" element={<OtherPosition />}/>
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
};

export default App;