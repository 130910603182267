import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Footer } from '../../components/Footer'
import { NavBar } from '../../components/NavBar'

const DisciplineEnginering = () => {
  return (
    <div>
      <NavBar />
      <div className='contact-bg'></div>
      <Container className='positions'>
              <div className='job'>
                <h3>Piping Engineer /<br /> Super Intendent</h3>
                <Row>
                  <Col className='job-description'>
                    <h5>Full Time</h5>
                  </Col>
                  <Col className='job-description'>
                    <h5>Contract</h5>
                  </Col>
                </Row>
              </div>

              <div className='job2'>
                <div className='job-location'>
                  <h3>Jakarta</h3>
                  <h5>Indonesia</h5>
                </div>
                <a href='job-2'>
                  <button>View And Apply</button>
                </a>
              </div>
            </Container>

            <Container className='positions'>
              <div className='job'>
                <h3>Lifting Engineer /<br /> Super Intendent</h3>
                <Row>
                  <Col className='job-description'>
                    <h5>Full Time</h5>
                  </Col>
                  <Col className='job-description'>
                    <h5>Contract</h5>
                  </Col>
                </Row>
              </div>

              <div className='job2'>
                <div className='job-location'>
                  <h3>Jakarta</h3>
                  <h5>Indonesia</h5>
                </div>
                <a href='job-5'>
                  <button>View And Apply</button>
                </a>
              </div>
            </Container>

            <Container className='positions'>
              <div className='job'>
                <h3>Mechanical <br />Engineer</h3>
                <Row>
                  <Col className='job-description'>
                    <h5>Full Time</h5>
                  </Col>
                  <Col className='job-description'>
                    <h5>Contract</h5>
                  </Col>
                </Row>
              </div>

              <div className='job2'>
                <div className='job-location'>
                  <h3>Jakarta</h3>
                  <h5>Indonesia</h5>
                </div>
                <a href='job-7'>
                  <button>View And Apply</button>
                </a>
              </div>
            </Container>

            <Container className='positions'>
              <div className='job'>
                <h3>Civil  Engineer /<br /> Super Intendent</h3>
                <Row>
                  <Col className='job-description'>
                    <h5>Full Time</h5>
                  </Col>
                  <Col className='job-description'>
                    <h5>Contract</h5>
                  </Col>
                </Row>
              </div>

              <div className='job2'>
                <div className='job-location'>
                  <h3>Jakarta</h3>
                  <h5>Indonesia</h5>
                </div>
                <a href='job-8'>
                  <button>View And Apply</button>
                </a>
              </div>
            </Container>

            <Container className='positions'>
              <div className='job'>
                <h3>Other Position</h3>
                <Row>
                  <Col className='job-description'>
                    <h5>Full Time</h5>
                  </Col>
                  <Col className='job-description'>
                    <h5>Contract</h5>
                  </Col>
                </Row>
              </div>

              <div className='job2'>
                <div className='job-location'>
                  <h3>Jakarta</h3>
                  <h5>Indonesia</h5>
                </div>
                <a href='job/other'>
                  <button>View And Apply</button>
                </a>
              </div>
            </Container>
      <Footer />
    </div>
  )
}

export default DisciplineEnginering