import React from 'react'
import { Col, Container, Row, Button, Modal, Form } from 'react-bootstrap'
import { Footer } from '../../components/Footer'
import { NavBar } from '../../components/NavBar'
import ScrollUp from '../../components/ScrollUp'
import { useState } from 'react'
import ApplyButton from '../../components/ui/ApplyButton'

const ProjectManager = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);  

  return (
    <div>
        <NavBar />
        <div className='contact-bg'></div>
        <Container className='career-info'>
            <Row>
                <Col>
                    <i class="fa-solid fa-building"></i>
                    <h3>Position</h3>
                    <h5>Project Manager</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-location-dot"></i>
                    <h3>Location</h3>
                    <h5>Jakarta, Indonsia</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-award"></i>
                    <h3>Education</h3>
                    <h5>Bachelor Degree</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-file"></i>
                    <h3>Working Experience</h3>
                    <h5>10 Years</h5>
                </Col>
            </Row>
        </Container>
        <Container className='job-detail'>
            <h2>Job Description</h2>
            <p>The Project Manager must have experience in executing PROJECT which include planning, coordinating, scheduling, and cost control of PROJECTs including setting of priorities and allocating manpower to assure all required technical, engineering, cost, safety standards, and quality management are fulfilled, and PROJECT objectives are achieved. Shall have experience in EPC Projects and have construction background.
            </p>

            <h2>Qualification</h2>
            <ul>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Fluent English Speaking & Writing</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Experience in Managing Construction Oil & Gas Projects</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Active User of Microsoft Programs</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Able to Manage Large Scale Projects (Over 150 B Rupiah)</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Stake Holder Managament active</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Willing travelling outside Jakarta</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Age Must be 35 - 50 years</li>
            </ul>
        </Container>
        <div className='apply-button'><button><ApplyButton label="Apply This Job" mailto="mailto:recruitment@soliner.co.id"/></button></div>
        <Footer />
        <ScrollUp />
    </div>
  )
}

export default ProjectManager;