import React from 'react'
import { Col, Container, Row, Button, Modal, Form } from 'react-bootstrap'
import { Footer } from '../../components/Footer'
import { NavBar } from '../../components/NavBar'
import ScrollUp from '../../components/ScrollUp'
import { useState } from 'react'
import ApplyButton from '../../components/ui/ApplyButton'


const ProcurementPurchasing = () => {
                    
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);  

  return (
    <div>
        <NavBar />
        <div className='contact-bg'></div>
        <Container className='career-info'>
            <Row>
                <Col>
                    <i class="fa-solid fa-building"></i>
                    <h3>Position</h3>
                    <h5>Procurement Manager</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-location-dot"></i>
                    <h3>Location</h3>
                    <h5>Jakarta, Indonesia</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-award"></i>
                    <h3>Education</h3>
                    <h5>Bachelor Degree</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-file"></i>
                    <h3>Working Experience</h3>
                    <h5>10 Years</h5>
                </Col>
            </Row>
        </Container>
        <Container className='job-detail'>
            <h2>Job Description</h2>
            <p>The Procurement Specialist shall be responsible for the development of the tender packages, qualification of vendors, performing tenders, evaluating qualification/tender responses, developing procurement strategy and plan for expediting, inspections, transportation, customs clearance, and receiving and storage of materials in oil and gas industry. He/she shall ensure procurement activities are conducted to meet the contract requirements including terms of Specifications and Schedule, and in accordance with the requirements of applicable laws, government regulations and accepted industry practices and COMPANY Specifications. To meet the minimum requirements of this position, the Procurement Specialist must have Bachelor of Science degree in related field or construction management degree with knowledge of International Trade Laws and applicable experience in procurement of structural, mechanical, electrical, instrument and control materials, subsea cable, pipelines and equipment for major offshore oil and gas production platform and pipeline projects. The Procurement Specialist must have experience in transportation and importation of goods into the South East Asia region including Indonesia. He/she must have a working knowledge of INCOTERMS, applicable industry practices and applicable regulations to procurement and importation of goods. The Procurement Manager must have a minimum of 15 years’ experience in Oil & Gas Industry including at least 10 years of gas/hydrocarbon facilities design experience as Procurement Manager
            </p>

            <h2>Qualification</h2>
            <ul>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Fluent English Speaking & Writing</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Experience in Construction Oil & Gas Projects</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Active User of Microsoft programs</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Hold A Weldling Inspector Certificate</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Understand EPC Procurement Project Sequence</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Able to develop Corporate Procurement Policy, Procedure & Work Instruction</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Able to manage large Scale Projects ( over 150 B Rupiah )</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Willing travelling outside Jakarta</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Age Must be 30 - 45 years</li>
            </ul>
        </Container>
        <div className='apply-button'><button><ApplyButton label="Apply This Job" mailto="mailto:recruitment@soliner.co.id"/></button></div>
        <Footer />
        <ScrollUp />
    </div>
  )
}

export default ProcurementPurchasing;