import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import { NavBar } from '../components/NavBar'
import { Container, Row, Col } from 'react-bootstrap'
import { Footer } from '../components/Footer'
import ScrollUp from '../components/ScrollUp'
import { Fade } from 'react-reveal';

const Contact = () => {

  const [buttonText, setButtonText] = useState('Send');
  const formValue = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setButtonText('Sending...')

    emailjs.sendForm('service_s4fvn0w', 'template_rq5b9li', '#contact-form', 'uNCOdD_NgI7u7cvd7')
      .then((result) => {
          console.log(result.text);
          alert("Message Sent..!")
          window.location.reload();
      }, (error) => {
          console.log(error.text);
          alert("Something Went Wrong!!!")
      });
  };

  return (
    <div className='contact'>
      <NavBar />
      <div className='contact-bg'>
      </div>
      <h3 className='contact-title'>Contact Us</h3>
      <Row xs={1} lg={2}>
          <Container className='contact-form'>
              <Col>
                <Fade>
                    <form ref={formValue} onSubmit={sendEmail} id='contact-form'>
                      <Row>
                        <Col size={24} sm={12} className="px-1">
                          <input type="text" name="user_name" placeholder="Your Name"/>
                        </Col>
                        <Col size={24} sm={12} className="px-1">
                          <input type="email" name="user_email" placeholder="Email Address"/>
                        </Col>
                        <Col size={12} className="px-1">
                          <textarea rows="6" name="message" placeholder="Message"></textarea>
                          <button type="submit" value='Send'><span>{buttonText}</span></button>
                        </Col>
                      </Row>
                    </form>
                </Fade>
              </Col>
          </Container>
        
        <Container className='contact-link'>
          <h1>Get In Touch</h1>
          <Col>
            <p><i class="fa-solid fa-location-dot"></i>GKM Green TowerJl TB Simatupang <span>Kav 89G 19th Floor Jakarta Selatan, </span><span>DKI Jakarta</span></p>
            <p><i class="fa-solid fa-phone"></i>021 22787919 </p>
            <p><i class="fa-solid fa-envelope"></i>info@soliner.co.id </p>
          </Col>
        </Container>
      </Row>
      <iframe width="100%" height="440" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=pt%20soliner+(PT.%20Solusi%20Industri%20Energi%20(Soliner))&amp;ie=UTF8&amp;t=&amp;z=13&amp;iwloc=B&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
      <Footer />
      <ScrollUp />
    </div>
  )
}

export default Contact