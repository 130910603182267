import React from 'react'
import { Col, Container, Row, Button, Modal, Form } from 'react-bootstrap'
import { Footer } from '../../components/Footer'
import { NavBar } from '../../components/NavBar'
import ScrollUp from '../../components/ScrollUp'
import { useState } from 'react'
import axios from 'axios'
import ApplyButton from '../../components/ui/ApplyButton'

const LeadQualityControl = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);  

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [uploaded_file, setUploadedFile] = useState(null);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleFileChange = (event) => {
        setUploadedFile(event.target.files[1]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('email', email);
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('subject', 'Job ---- Apply');
        formData.append('message', message);

        axios.post('http://localhost:4000/send-email', formData, uploaded_file, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
        })
        .then(response => {
            console.log('Data berhasil dikirim:', response.data);
        })
        .catch(error => {
            console.error('Terjadi kesalahan:', error.message);
        });
    };
    
  return (
    <div>
        <NavBar />
        <div className='contact-bg'></div>
        <Container className='career-info'>
            <Row>
                <Col>
                    <i class="fa-solid fa-building"></i>
                    <h3>Position</h3>
                    <h5>QA / QC Manager</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-location-dot"></i>
                    <h3>Location</h3>
                    <h5>Jakarta, Indonesia</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-award"></i>
                    <h3>Education</h3>
                    <h5>Bachelor Degree</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-file"></i>
                    <h3>Working Experience</h3>
                    <h5>10 Years</h5>
                </Col>
            </Row>
        </Container>
        <Container className='job-detail'>
            <h2>Job Description</h2>
            <p>The project quality organization shall include a full time Quality Manager with reporting lines to senior level Project Manager/Director and to CONTRACTOR’s Corporate Quality Management representative. The Quality Manager (QM) shall be responsible for developing and implementing a structured Quality Management System (QMS) across the project, in conformance with CONTRACTOR quality management processes, and for directing Quality Management activities throughout design, procurement, construction, commissioning and handover. The QM shall be responsible for establishing the project quality objectives, authoring and maintaining the CONTRACTOR Quality Plan and other applicable quality documents, and managing the project quality resources to execute the quality activities. The Quality Manager shall be supported by a Quality Assurance and Quality Control organization suitable for meeting the requirements of the WORK. All Quality Management and QA/QC personnel shall be competency assessed and their training needs identified prior to commencement of their assigned WORK tasks.
            </p>

            <h2>Responsibilities</h2>
            <ul>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Fluent English Speaking & Writing</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Experience in Construction Oil & Gas Projects</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Active User of Microsoft Programs</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Hold A Weldling Inspector Certificate </li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Understand EPC Quality Project Sequence</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Able to develop Corporate Quality Policy, Procedure & Work Instruction  according to ISO & Company needs</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Able to manage large Scale Projects ( over 150 B Rupiah )</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Willing travelling outside Jakarta</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Age Must be 30 - 50 years</li>
            </ul>
        </Container>
        <div className='apply-button'><button><ApplyButton label="Apply This Job" mailto="mailto:recruitment@soliner.co.id"/></button></div>
        <Footer />
        <ScrollUp />
    </div>
  )
}

export default LeadQualityControl;