import React from 'react'
import { Col, Container, Row, Button, Modal, Form } from 'react-bootstrap'
import { Footer } from '../../components/Footer'
import { NavBar } from '../../components/NavBar'
import ScrollUp from '../../components/ScrollUp'
import { useState } from 'react'
import ApplyButton from '../../components/ui/ApplyButton'


const MechanicalEngineer = () => {
                    
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);  

  return (
    <div>
        <NavBar />
        <div className='contact-bg'></div>
        <Container className='career-info'>
            <Row>
                <Col>
                    <i class="fa-solid fa-building"></i>
                    <h3>Position</h3>
                    <h5>Mechanical Engineer / Super Intendent</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-location-dot"></i>
                    <h3>Location</h3>
                    <h5>Jakarta, Indonesia</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-award"></i>
                    <h3>Education</h3>
                    <h5>Bachelor Degree</h5>
                </Col>
                <Col>
                    <i class="fa-solid fa-file"></i>
                    <h3>Working Experience</h3>
                    <h5>5 Years</h5>
                </Col>
            </Row>
        </Container>
        <Container className='job-detail'>
            <h2>Job Description</h2>
            <p>The Engineer must have a minimum of 5 years’ experience in the Oil & Gas industry and must have worked on at least 2 development PROJECTs. They must have a bachelor’s degree in their relevant discipline. Able to manage direct work force and Contractor / Subcontractors
            </p>

            <h2>Qualification</h2>
            <ul>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Fluent English Speaking & Writing</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Experience in Supervising  Construction Oil & Gas Projects</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Active User of Microsoft programs</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Autocad Active user</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Active User of Navis</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Able to manage Direct Work force & Subcontractors</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Able to estimate bill of quantity & Cost Structure</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Willing travelling outside Jakarta ( Site Based )</li>
                <i class="fa-solid fa-circle-dot"></i>
                <li>Age Must be 30 - 40 years</li>
            </ul>
        </Container>
        <div className='apply-button'><button><ApplyButton label="Apply This Job" mailto="mailto:recruitment@soliner.co.id"/></button></div>
        <Footer />
        <ScrollUp />
    </div>
  )
}

export default MechanicalEngineer;