import React from 'react';
import logo from '../assets/img/logo3.png'
import { Zoom } from 'react-reveal';
import { Col, Row } from 'react-bootstrap';

export const Footer = () => {
  return (
    <footer>
      <div>
        <Row style={{justifyContent: 'left'}} xs={1} md={2}>
          <Col size={18} sm={6} className='left-footer'>
            <img src={logo} alt='logo' />

            <p>We provided Effective And Simple Solution For Your Industrial Needs</p>

            <div className="social-icon">
                <Zoom delay={1000}>
                  <a href='https://twitter.com/EnergiSolusi'><i class="fab fa-twitter"></i></a>
                </Zoom>

                <Zoom delay={1100}>
                  <a href="https://www.linkedin.com/in/soliner-indonesia-6938b525a/"><i class="fab fa-linkedin-in"></i></a>
                </Zoom>                

                <Zoom delay={1200}>
                  <a href="https://www.instagram.com/soliner_id/"><i class="fab fa-instagram"></i></a>
                </Zoom>
            </div>
          </Col>

          <div className='row'>
            <Col style={{width: '100%'}}>
              <div className='footer-link'>
                <a href='/about'>About</a>
                <a href='/services'>Services</a>
                <a href='/career'>Career</a>
                <a href='/contact-us'>Contact</a>
                <a href='/gallery'>Gallery</a>
              </div>
            </Col>

            <Col style={{width: '100%'}}>
              <div className='footer-link'>
                <h5>Come and Join us!</h5>
                <a href='/career'>Apply here.</a>
              </div>
            </Col>
          </div>
        </Row>
      </div>
      <div className='copyright'>
        &copy; PT Solusi Industri Energi All Right Reserved
      </div>
    </footer>
  );};